<template>
  <div class="container-fluid">
    <div class="row justify-content-center py-3 align-items-center">
        <div class="mt-2 qrText ">SCAN QR CODE TO PAY</div>
      <div class="w-100 d-flex align-items-center justify-content-center" v-if="qrData">
        <img
          :src="qrData"
          height="320"
          width="390"
          class="mx-auto d-block mt-2"
        />
      </div>
      <div class="mt-2 qrText" v-if="amount">{{amount}}&nbsp;{{curr_code}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    curr_code: String,
    amount: String,
    qrData: {
      type: String,
      default: "",
    },
  },
  computed: {},
  methods: {
  },
};
</script>

<style>
.qrText {
    font-size: 20px;
    color: black;
    font-weight: bolder;
    text-align: center;
}
</style>
