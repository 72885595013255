<template>
  <div class="table-bg p-3" style="width: 100%">
    <div class="table-light-bg" style="">
      <table>
        <tbody>
          <tr>
            <td v-if="showDataArr && showDataArr.length">{{ showDataArr[0].movie_name }} -
              {{ showDataArr[0].mf_lang_name }} - {{ showDataArr[0].rating }} |
              {{ showDataArr[0].screen_name }}</td>
          </tr>
        </tbody>
      </table>
      <table class v-if="fnbList.length > 0">
        <thead>
          <tr>
            <td>Item</td>
            <td>Quantity</td>
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(fnbitem, fnbitemKey) in fnbList" :key="fnbitemKey">
            <td class="align-text-center pr-4">{{ fnbitem.item_name }}</td>
            <td class="align-text-center pr-4">{{ fnbitem.quantity }}</td>
            <td class="align-text-center pr-5">
              {{ currFormat(fnbitem.fp_total_amount * fnbitem.quantity) }}
              {{ currCode }}
            </td>
          </tr>
          <tr>
            <td>
              <h6 class="mb-0 font-weight-bold">Sub Total :</h6>
            </td>
            <td></td>
            <td>
              <h6 class="mb-0 font-weight-bold">
                {{ currFormat(subTotal) }}
                {{ currCode }}
              </h6>
            </td>
          </tr>

          <tr>
            <td>
              <h6 class="mb-0 font-weight-bold" v-if="isEpix">VAT</h6>
              <h6 class="mb-0 font-weight-bold" v-else>{{ $t("tax") }}</h6>
            </td>
            <td></td>
            <td>
              <h6 class="mb-0 font-weight-bold">
                {{ currFormat(grossTotal - subTotal) }}
                {{ currCode }}
              </h6>
            </td>
          </tr>

          <tr v-if="RateCardAddonsChargesFinalAmount > 0">
            <td>
              <h6 class="mb-0 font-weight-bold">Additional Charges</h6>
            </td>
            <td></td>
            <td>
              <h6 class="mb-0 font-weight-bold">
                {{ currFormat(RateCardAddonsChargesFinalAmount) }}
                {{ currCode }}
              </h6>
            </td>
          </tr>

          <tr v-if="offerData && offerData.length > 0">
            <td style="color: #dc3545">
              <h6 class="mb-0 font-weight-bold">Offer Applied</h6>
            </td>
            <td></td>
            <td style="color: #dc3545">
              <h6 class="mb-0 font-weight-bold">
                - {{ currFormat(offerData[0].offer_price) }}
                {{ currCode }}
              </h6>
            </td>
          </tr>
          <tr>
            <td class="total_amt_border">
              <h5 class="mb-0 font-weight-bold">Gross Total :</h5>
            </td>
            <td></td>
            <td class="total_amt_border">
              <h5 class="mb-0 font-weight-bold">
                {{ currFormat(grossTotal) }}
                {{ currCode }}
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { CLIENT_NAME } from "@/helper/Role";
export default {
  props: {
    fnbList: {
      type: Array,
    },
    fnbtotalamount: {
      type: Number,
    },
    currCode: {
      type: String,
    },
    charges: {
      type: Array,
    },
    showDataArr: {
      type: Array,
    },
    offerData: {
      type: Array,
    },
    RateCardAddonsChargesFinalAmount: {
      type: Number,
    },
    addonArray: {
      type: Array,
    },
  },
  computed: {
    subTotal() {
      const fnbList = this.fnbList;

      return fnbList && fnbList.length > 0
        ? this.currFormat(
          fnbList.reduce((ac, x) => (ac += x.fp_amount * x.quantity), 0)
        )
        : "-";
    },
     isEpix() {
      let hostname = window.location.hostname;
      return   hostname.includes(('epixcinemas'))
    },
    grossTotal() {
      const fnbList = this.fnbList;
      const offerData = this.offerData;
      const offerDataValue =
        offerData.length > 0 && offerData[0].offer_price
          ? offerData[0].offer_price
          : 0;
      const RateCardAddonsChargesFinalAmount = this
        .RateCardAddonsChargesFinalAmount
        ? this.RateCardAddonsChargesFinalAmount
        : 0;

      return fnbList && fnbList.length > 0
        ? this.currFormat(
          // fnbList[0].fp_total_amount * fnbList[0].quantity
          fnbList.reduce(
            (ac, x) => (ac += x.fp_total_amount * x.quantity),
            0
          ) +
          RateCardAddonsChargesFinalAmount -
          offerDataValue
        )
        : "-";
    },
  },
};
</script>
<style scoped>
table {
  width: 100%;
}

table,
th,
td {
  border-bottom: 1px solid #000;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}

.table-bg {
  background-color: #fff;
  height: 100%;
  /* transform: scale(0.7); */
  transform: top;
  /* margin-left: -150px; */
}

.table-light-bg {
  box-shadow: rgb(128 128 128 / 68%) 1px 1px 9px 1px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 20px;
  border-radius: 20px;
}
</style>
